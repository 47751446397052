<template>
  <div class="tg-page">
    <!--<div class="slogn">
      <h1>技术之美</h1>
      <p>我常常失败，但我并不气馁，因为这证明我时常在与自己斗争！</p>
    </div>-->
    <div class="item-list">
      <div class="item" v-for="item of config.technology.technologyList" :key="item.id">
        <img :src="item[$i18n.locale].img" />
        <div class="text">
          <h2>{{item[$i18n.locale].title}}</h2>
          <p>{{item[$i18n.locale].subTitle}}</p>
          <a :href="item.link" target="_blank">{{item[$i18n.locale].btnText}}</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "technology",
};
</script>

<style lang="scss" scoped>
.tg-page {
  margin-top: 40px;
  margin-bottom: 40px;
  max-width: 1024px;
  width: 100%;
  padding: 0 20px;
  .item-list {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
}
.item {
  width: 100%;
  overflow: hidden;
  border: 0 solid #7b4e4e;
  border-radius: 0.375rem;
  border-width: 2px;
  border-color: #92919126;
  img {
    height: 277px;
    width: 100%;
    object-fit: cover;
  }
  .text {
    padding: 1.5rem;
    h2 {
      font-weight: 700;
      line-height: 2rem;
      letter-spacing: -0.025em;
      font-size: 1.5rem;
      margin-bottom: 0.75rem;
      margin: 0;
    }
    p {
      overflow: hidden;
      opacity: 0.6;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    a {
      color: #409eff;
      cursor: pointer;
    }
    a:hover {
      margin-left: 5px;
    }
  }
}
@media only screen and (max-width: 950px) {
  .item img {
    height: 30vw;
  }
}
@media only screen and (max-width: 730px) {
  .tg-page {
    margin-top: 0px;
    .item-list {
      display: grid;
      grid-template-columns: 1fr;
    }
  }
  .item img {
    height: 60vw;
  }
}
</style>